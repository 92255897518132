import { BidIcon, BudgetIcon, ProjectIcon, UserIcon } from "assets/icons";
import {
  Feature1Image,
  Feature2Image,
  Feature3Image,
  Feature4Image,
} from "assets/svg";

const features = [
  {
    badgeTitle: "Verified professionals",
    badgeIcon: UserIcon,
    cardTitle: "Choose from Verified Contractors and Consultants",
    cardBody:
      "Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
    image: Feature1Image,
    linkTo: "",
  },
  {
    badgeTitle: "Transparent bid process",
    badgeIcon: BidIcon,
    cardTitle: "Receive and Evaluate Bids Online",
    cardBody:
      "Conduct competitive bids online to give you best value for money. Automate the tender process from issuing invitations to bid, to receiving and analyzing bids.",
    image: Feature2Image,
    isWhiteColor: true,
    linkTo: "",
  },
  {
    badgeTitle: "Project clarity",
    badgeIcon: ProjectIcon,
    cardTitle: "Gain clarity and control of your Project",
    cardBody: "Don’t miss a thing, track project progress in real time.",
    image: Feature3Image,
    isWhiteColor: true,
    linkTo: "",
  },
  {
    badgeTitle: "Budgeting",
    badgeIcon: BudgetIcon,
    cardTitle: "Cost Control",
    cardBody:
      "Gain total visibility and control of project budget and expenses.",
    image: Feature4Image,
    isDeepBlueColor: true,
    linkTo: "",
  },
];

const faqData = [
  {
    question: "How Long will the process take?",
    answer: "You can sign up and create a project on bnkle within 7 minutes.",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "If you select our Standard and Enterprise Plans, you will have access to the entire pool of contractors on the platform. For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you wll be required to sign contract documents if you award or are awarded a contract on the platform.",
  },

  {
    question: "How can Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a construction management software tool that helps construction professionals manage and organize their projects, including tasks such as budgeting, scheduling, and document management.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, document management, and communication tools.\n In the future, we will offer features such as time tracking, resource allocation, and quality control.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tools, which means they can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules, as well as assign tasks to team members. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

const testimonials = [
  {
    message:
      "The Bnklé platform was especially important as a central point to constantly communicate the implementation of the project, allowing us to see pictures of work progress, view/review invoices, and monitor spending, among others. This level of communication, transparency, and coordination was especially crucial as I was overseas during the start of the project. Needless to say, the result is nothing short of impressive.",
    name: "Bukky Shonibare",
    title: "Executive Director, Invictus Africa",
    backgroundColor: "#FFC59A",
  },
  {
    message:
      "Bnkle is a wonderful project management tool. It has enabled me monitor my project remotely. From the negotiations to the actual site implementation with visual updates. It also provides a seamless interface for all the actors on the project.",
    name: "Samson Opaluwah. PhD.",
    title: "Chairman, Council of Registered Builders of Nigeria (CORBON)",
    backgroundColor: "#DAEDBB",
    showSecondColor: true,
  },
];

export { features, faqData, testimonials };
