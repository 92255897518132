import { FC } from "react";
import { EstimateSection, TestimonialSection } from "components";
import { HeroSection } from "./components/HeroSection";
import { FeaturesSection } from "./components/FeaturesSection";
import { FaqSection } from "./components/FaqSection";

export const DoctorsPage: FC = () => {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <TestimonialSection />
      <FaqSection />
      <EstimateSection />
    </>
  );
};
