import { useState, FC } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

interface Props {
  question: string;
  answer: string;
}
export const FaqComponent: FC<Props> = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div className="cursor-pointer">
      <div
        onClick={() => setShowAnswer(!showAnswer)}
        className={`flex justify-between items-center rounded-xl py-3 px-6 bg-ashShade-0 ${showAnswer && "rounded-b-none"}`}
      >
        <span className=" text-bblack-1 text-xl font-SatoshiMedium font-medium">
          {question}
        </span>
        {showAnswer ? (
          <AiOutlineMinus color="#2C3E50" />
        ) : (
          <AiOutlinePlus color="#2C3E50" />
        )}
      </div>
      {showAnswer && (
        <p className="text-bash py-3 px-6  border-[1px] rounded-b-xl font-SatoshiRegular text-base">
          {answer}
        </p>
      )}
    </div>
  );
};
