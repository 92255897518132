import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import { CustomSwiperNavButton } from "./CustomSwiperNavButton";
import { TestimonialCard } from "./TestimonialCard";
import { Swiper as SwiperType } from "swiper";

interface Props {
  buttonTittle: string;
  data: any[];
}

export const CustomSwiper: FC<Props> = ({ buttonTittle, data }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const handlePrev = () => {
    if (swiperRef) swiperRef.slidePrev();
  };
  const handleNext = () => {
    if (swiperRef) swiperRef.slideNext();
  };
  return (
    <div className="relative">
      <CustomSwiperNavButton
        title={buttonTittle}
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={10}
        autoplay={{
          delay: 5000,
        }}
        modules={[Autoplay, Pagination]}
        onSwiper={(swiper: SwiperType) => setSwiperRef(swiper)}
      >
        {data.map((testimonial, index) => (
          <SwiperSlide>
            {({ isNext }) => (
              <div className={isNext ? "-translate-x-32" : ""}>
                <TestimonialCard
                  key={index}
                  message={testimonial.message}
                  name={testimonial.name}
                  title={testimonial.title}
                  backgroundColor={testimonial.backgroundColor}
                  showSecondColor={testimonial.showSecondColor}
                />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
