import { FC } from "react";

interface Props {
  icon?: string;
  title: string;
  isWhiteColor?: boolean;
}

export const SectionBadge: FC<Props> = ({ icon, title, isWhiteColor }) => {
  return (
    <div
      className={`flex gap-2 items-center py-2 px-3 border-2 bg-transparent  border-solid rounded-3xl ${isWhiteColor ? "border-PRIMARY" : "border-white"}`}
    >
      <img src={icon} alt="" />
      <p
        className={`text-base ${isWhiteColor ? "text-PRIMARY" : "text-white"}`}
      >
        {title}
      </p>
    </div>
  );
};
