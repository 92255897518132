import React from "react";
import star from "assets/star.svg";

const Banner = () => {
  return (
    <div className="relative z-[99999999] h-14 bg-gradient-to-r to-[#FBB040] from-[#EF4136] w-full flex items-center justify-center">
      <div className="flex items-center gap-4 text-white">
        <img src={star} alt="" className="h-4" />
        <span className=" font-bold ">
          Fall 2023{" "}
          <a
            href="https://twitter.com/colabsyxe/status/1730625087764881555"
            target="_blank"
            className="underline cursor-pointer"
          >
            Co.Launch
          </a>{" "}
          Winner
        </span>
        <img src={star} alt="" className="h-4 rotate-180" />
      </div>
    </div>
  );
};

export default Banner;
