import { FC } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface Props {
  title: string;
  onPrev: () => void;
  onNext: () => void;
}

export const CustomSwiperNavButton: FC<Props> = ({ title, onPrev, onNext }) => {
  return (
    <div className="referral-container mr-16 md:ml-0">
      <div className="flex items-center justify-between mb-12 w-full">
        <p className="text-3xl font-SatoshiBold text-bblack-1">{title}</p>
        <div className="hidden lg:flex gap-4">
          <button
            onClick={onPrev}
            className="flex justify-center items-center border border-black rounded-full w-8 h-8"
          >
            <FiChevronLeft size={16} color="black" />
          </button>
          <button
            onClick={onNext}
            className="flex justify-center items-center border border-black rounded-full w-8 h-8"
          >
            <FiChevronRight size={16} color="black" />
          </button>
        </div>
      </div>
    </div>
  );
};
