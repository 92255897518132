import { Outlet } from "react-router-dom";
import Footer from "components/newShared/Footer";
import { NavBar } from "./navbar/Navbar";
import Header from "./newShared/Header";

export const Layout = () => {
  return (
    <div className="w-full font-SatoshiRegular">
      <Header
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`}
      />
      <Outlet />
      <Footer />
    </div>
  );
};


