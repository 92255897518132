import { FC } from "react";
import Button from "components/shared/Button";
import { SectionBadge } from "./SectionBadge";
import { Link } from "react-router-dom";

interface Props {
  badgeTitle?: string;
  badgeIcon?: string;
  cardTitle: string;
  cardBody: string;
  largeScreenButtonText?: string;
  smallScreenButtonText?: string;
  buttonBackgroundColor?: string;
  buttonColor?: string;
  isWhiteColor?: boolean;
  linkTo?: string;
  showPadding?: boolean;
  onClick?: () => {};
}

export const CardComponent: FC<Props> = ({
  badgeIcon,
  badgeTitle,
  cardBody,
  cardTitle,
  isWhiteColor,
  largeScreenButtonText,
  smallScreenButtonText,
  buttonBackgroundColor,
  buttonColor,
  linkTo,
  showPadding = true,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-col gap-8 lg:items-baseline ${showPadding ? "px-4 lg:px-8" : "px-0 lg:px-0"}`}
    >
      {badgeTitle && (
        <div className="flex">
          <SectionBadge
            title={badgeTitle}
            icon={badgeIcon}
            isWhiteColor={isWhiteColor}
          />
        </div>
      )}
      <div className="flex flex-col gap-6">
        <p
          className={`font-SatoshiBold text-2xl ${isWhiteColor ? "text-bblack-1" : "text-white"}`}
        >
          {cardTitle}
        </p>
        <p
          className={`font-SatoshiMedium text-lg ${isWhiteColor ? "text-bash" : "text-bidsbg"}`}
        >
          {cardBody}
        </p>
      </div>
      {largeScreenButtonText && (
        <Link to={linkTo ?? ""} className="hidden lg:flex">
          <Button
            label={largeScreenButtonText}
            type="secondary"
            style={{
              borderColor: "#EAECF0",
              backgroundColor: buttonBackgroundColor ?? "#fff",
              color: buttonColor ?? "black",
            }}
            onClick={onClick}
          />
        </Link>
      )}
      <Link
        to={linkTo ?? ""}
        className="flex flex-col lg:items-baseline lg:hidden"
      >
        <Button
          label={smallScreenButtonText ?? largeScreenButtonText}
          type="secondary"
          style={{
            borderColor: "#EAECF0",
            backgroundColor: buttonBackgroundColor ?? "#fff",
            color: buttonColor ?? "black",
          }}
          onClick={onClick}
        />
      </Link>
    </div>
  );
};
