import { FC } from "react";
import { CardComponent } from "components";
import { CalculatorImage } from "assets/svg";

export const EstimateSection: FC = () => {
  return (
    <div className="bg-white">
      <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
        <div className="flex flex-col gap-5 justify-between items-center lg:flex-row py-12 lg:pr-12 lg:pl-4 bg-bblack-2 referral-container rounded-xl lg:rounded-2xl">
          <div className="lg:w-[55%]">
            <CardComponent
              cardTitle="Get a quick project estimate"
              cardBody="Estimate the cost of building a home with our new and improved construction cost calculator"
              largeScreenButtonText="Get Estimate"
              buttonColor="#437ADB"
              buttonBackgroundColor="#fff"
              linkTo={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
            />
          </div>
          <div className="w-full lg:max-w-[300px] flex flex-col gap-6 px-4 lg:px-0">
            <img src={CalculatorImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
