import { FC } from "react";

interface Props {
  message: string;
  name: string;
  title: string;
  backgroundColor: string;
  showSecondColor?: boolean;
}

export const TestimonialCard: FC<Props> = ({
  message,
  name,
  title,
  backgroundColor,
  showSecondColor,
}) => {
  return (
    <div
      className={`flex flex-col justify-between gap-8 lg:gap-0 p-6 rounded-xl lg:mr-36 lg:h-[33em] ${showSecondColor ? "bg-[#DAEDBB]" : "bg-[#FFC59A]"} text-bblack-1`}
    >
      <p className="text-3xl lg:text-4xl font-SatoshiMedium">{message}</p>
      <div>
        <p className="text-2xl font-satoshi font-semibold">{name}</p>
        <p className="text-sm font-SatoshiMedium">{title}</p>
      </div>
    </div>
  );
};
