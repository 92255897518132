import { FC } from "react";
import { testimonials } from "../../pages/doctor/constant";
import { CustomSwiper } from "components";
import { TestimonialCard } from "components/containers/TestimonialCard";

export const TestimonialSection: FC = () => {
  return (
    <div className="bg-white">
      <div className="py-8 md:py-16 xl:py-20 px-5 lg:pr-0 md:px-16 lg:pl-16">
        {/* md:px-16 lg:pl-16 */}

        <div className="hidden lg:block lg:max-w-[calc(100vw-((100vw-1234px)/2))] lg:mr-0 md:ml-auto">
          <CustomSwiper
            buttonTittle="What users are saying"
            data={testimonials}
          />
        </div>
        <div className="lg:hidden flex flex-col gap-12">
          <p className="text-3xl font-SatoshiBold text-bblack-1">
            What users are saying
          </p>
          <div className="flex flex-col gap-8 ">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                message={testimonial.message}
                name={testimonial.name}
                title={testimonial.title}
                backgroundColor={testimonial.backgroundColor}
                showSecondColor={testimonial.showSecondColor}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
